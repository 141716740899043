import React from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import Img from 'gatsby-image'

const Quote = ({ slice }) => {

  return (
    <blockquote className={`component-${slice.slice_type} relative border-l-2 pl-4 sm:pl-6`}>
      <div className='my-4 md:text-2xl relative'>
        <div dangerouslySetInnerHTML={{__html:get(slice.primary, 'quote.html')}} />
      </div>
      <footer className='flex items-center justify-start'>
        {get(slice.primary.image, 'fixed') && (
          <Img className='focus:outline-none rounded-full overflow-hidden w-20 h-20 mr-3' fixed={slice.primary.image.fixed} alt={slice.primary.image.alt} />
        )}
        <div>
          <p className='md:text-2xl'>{get(slice.primary, 'author')}</p>
          <p className='text-blue-600'>{get(slice.primary, 'role') && <span>{get(slice.primary, 'role')}</span>}{(get(slice.primary, 'company') && get(slice.primary, 'role')) && ','} {get(slice.primary, 'company') && <span> {get(slice.primary, 'company')}</span>}</p>
        </div>
      </footer>
    </blockquote>
  )
}

Quote.propTypes = {
  slice: PropTypes.object.isRequired,
}

export default Quote
