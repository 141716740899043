import React from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get';


const Map = ({ slice }) => {
  return (
    <div className={`md:flex component-${slice.slice_type}`}>
      <div className='md:mr-20 xl:mr-20'>
        <div dangerouslySetInnerHTML={{__html:get(slice.primary, 'text.html')}} />
      </div>
      <div id='map' className='border flex-1 overflow-hidden relative rounded-md' style={{paddingBottom:'40%'}}>
        <iframe
          className='absolute inset-0'
          width='100%'
          height='100%'
          title='Google Map of Venue'
          src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyD9wReXDmZXESKfYf41UWyUaDRcYDumkFc&q=${slice.primary.map_url.latitude},${slice.primary.map_url.longitude}`}
          allowFullScreen
        />
      </div>
    </div>
  )
}

Map.propTypes = {
  slice: PropTypes.object.isRequired,
}

export default Map
