import React from 'react'

const Contact = ({ lang, slice }) => {
  var formUrl = '/success'
  var formName = 'contact'
  if (lang !== 'en-gb') {
    formUrl = '/' + lang + '/success'
    formName = 'contact-' + lang
  }
  return (
    <>
      {slice.primary.title && (
        <div dangerouslySetInnerHTML={{__html:slice.primary.title.html}} />
      )}
      <form
        name={formName}
        method='POST'
        action={formUrl}
        data-netlify='true'
        data-netlify-honeypot='surname-hp'
      >
        <input type='hidden' name='surname-hp' />
        <input type='hidden' name='form-name' value={formName} />
        <div className='mb-4'>
          <label className='block font-medium text-gray-700' htmlFor='name'>{slice.primary.name_label}</label>
          <div className='mt-1'>
            <input className='appearance-none border border-gray-600 rounded w-full py-3 px-4 focus:outline-none focus:ring-2 ring-offset-2 focus:ring-brand-blue focus:ring-opacity-50' type='text' name='name' id='name' aria-required='true' required />
          </div>
        </div>
        <div className='mb-4'>
          <label className='block font-medium text-gray-700' htmlFor='email'>{slice.primary.email_label}</label>
          <div className='mt-1'>
            <input className='appearance-none border border-gray-600 rounded w-full py-3 px-4 focus:outline-none focus:ring-2 ring-offset-2 focus:ring-brand-blue focus:ring-opacity-50' type='email' name='email' id='email' aria-required='true' required />
          </div>
        </div>
        <div className='mb-4'>
          <label className='block font-medium text-gray-700' htmlFor='message'>{slice.primary.message_label}</label>
          <div className='mt-1'>
            <textarea rows='4' className='appearance-none border border-gray-600 rounded w-full py-3 px-4 focus:outline-none focus:ring-2 ring-offset-2 focus:ring-brand-blue focus:ring-opacity-50' name='message' id='message' aria-required='true' required></textarea>
          </div>
        </div>
        <button type='submit' className='w-full block button text-xl'>{slice.primary.button_text}</button>
      </form>
    </>
  )
}

export default Contact
