import { Transition } from '@headlessui/react'
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import Img from 'gatsby-image'

const LinkedIn = ({ link }) => {
  if (!link) return null
  return (
    <a className='text-gray-400 hover:text-gray-500 mb-3 focus:outline-none focus:ring-2 ring-offset-2 focus:ring-brand-blue focus:ring-opacity-50 block' target='_blank' rel='noopener noreferrer' href={link}>
      <span className='sr-only'>LinkedIn</span>
      <svg className='w-6 h-6 mt-2' fill='currentColor' viewBox='0 0 20 20' aria-hidden='true'>
        <path fillRule='evenodd' d='M16.338 16.338H13.67V12.16c0-.995-.017-2.277-1.387-2.277-1.39 0-1.601 1.086-1.601 2.207v4.248H8.014v-8.59h2.559v1.174h.037c.356-.675 1.227-1.387 2.526-1.387 2.703 0 3.203 1.778 3.203 4.092v4.711zM5.005 6.575a1.548 1.548 0 11-.003-3.096 1.548 1.548 0 01.003 3.096zm-1.337 9.763H6.34v-8.59H3.667v8.59zM17.668 1H2.328C1.595 1 1 1.581 1 2.298v15.403C1 18.418 1.595 19 2.328 19h15.34c.734 0 1.332-.582 1.332-1.299V2.298C19 1.581 18.402 1 17.668 1z' clipRule='evenodd'></path>
      </svg>
    </a>
  )
}


function Headshot({ individual }) {
  const [isOpen, setIsOpen] = useState(false)
  return (
    <>
      {individual.name ? (
        <button onClick={() => setIsOpen(!isOpen)} className='rounded-full overflow-hidden w-36 h-36 md:w-48 md:h-48 transform translate ease duration-200 hover:shadow-lg hover:scale-105 focus:outline-none focus:ring-2 ring-offset-2 focus:ring-brand-blue focus:ring-opacity-50 focus:rounded-full'>
          <Img fixed={individual.image.fixed} alt={individual.image.alt} className='w-36 h-36 md:w-48 md:h-48' />
        </button>
      ):(
        <Img fixed={individual.image.fixed} alt={individual.image.alt} className='w-36 h-36 md:w-48 md:h-48' />
      )}
      <div className='flex flex-col justify-center -mx-8'>
        <div className='md:text-2xl mt-3'>{individual.name}</div>
        <div className='text-sm md:text-base text-gray-600'>{individual.role}</div>
        <div className='mx-auto'><LinkedIn link={get(individual.linkedin, 'url')} /></div>
      </div>
      <Transition
        show={isOpen}
        className='fixed inset-0 z-40'
      >
        <div className='bg-black opacity-20 fixed inset-0 z-10'/>
        <div className='w-screen max-w-md fixed right-0 z-20'>
          <div className='h-screen text-left flex flex-col bg-white shadow-lg overflow-y-scroll'>
            <div className='p-4 sm:px-6'>
              <div className='flex items-center justify-between'>
                <div id='slide-over-heading'>
                  <h2 className='text-xl md:text-2xl font-medium text-gray-900 m-0'>{individual.name}</h2>
                  <div className='text-sm md:text-base text-gray-600'>{individual.role}</div>
                </div>
                <div className='ml-3 h-7 flex items-center'>
                  <button onClick={() => setIsOpen(!isOpen)} className='bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 ring-offset-2 focus:ring-brand-blue focus:ring-opacity-50'>
                    <span className='sr-only'>Close panel</span>
                    <svg className='h-6 w-6' xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='currentColor' aria-hidden='true'>
                      <path stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M6 18L18 6M6 6l12 12' />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
            <div className='pb-1 sm:pb-6'>
              <Img className='w-full h-72 object-top' fixed={individual.image.fixed} alt={individual.image.alt} />
            </div>
            <div className='px-4 sm:px-6'>
              <div dangerouslySetInnerHTML={{__html:individual.bio.html}} />
              <LinkedIn link={get(individual.linkedin, 'url')} />
            </div>
          </div>
        </div>
      </Transition>
    </>
  )
}

const Headshots = ({ slice }) => {
  return (
    <div className='pt-4 pb-16'>
      {get(slice.primary, 'title.html') && (
        <div className='mb-10 font-bold' dangerouslySetInnerHTML={{__html:get(slice.primary, 'title.html')}} />
      )}
      <ul className={`component-${slice.slice_type} grid grid-cols-2 gap-5 md:grid-cols-3 md:gap-10`}>
        {slice.items.map((individual, index) => {
          return (
            <li className='text-center mx-auto mb-4' key={`individual-${index}`}>
              <Headshot individual={individual} />
            </li>
          )
        })}
      </ul>
    </div>
  )
}

Headshots.propTypes = {
  slice: PropTypes.object.isRequired,
}

export default Headshots
