import React from 'react'
import PropTypes from 'prop-types'

const Accordion = ({ slice }) => {
  return (
    <div className={`component-${slice.slice_type} pb-4`}>
      <div className='mb-3 lg:mb-6'>
        {slice.primary.title && (
          <div dangerouslySetInnerHTML={{__html:slice.primary.title.html}} />
        )}
        {slice.primary.text && (
          <div dangerouslySetInnerHTML={{__html:slice.primary.text.html}} />
        )}
      </div>
      {slice.items.map((accordion, index) => {
        return (
          <details className='bg-white rounded overflow-hidden shadow-sm transform transition-all border mb-3' key={`accordion-${index}`}>
            <summary className='bg-white hover:text-blue-500 m-0 text-xl block cursor-pointer w-full text-left p-4 outline-none'>
              <div className='inline-block' dangerouslySetInnerHTML={{__html:accordion.question.html}}/>
              <div className='float-right indicator'><svg xmlns='http://www.w3.org/2000/svg' height='30' width='30' viewBox='0 0 24 24' fill='currentColor'><path d='M0 0h24v24H0V0z' fill='none'></path><path d='M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z'></path></svg></div>
            </summary>
            <div className='text border-t p-4' dangerouslySetInnerHTML={{__html:accordion.answer.html}} />
          </details>
        )
      })}
    </div>
  )
}

Accordion.propTypes = {
  slice: PropTypes.object.isRequired,
}

export default Accordion
