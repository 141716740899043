import React from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import CheckLink from '../components/checkLink'
import Img from 'gatsby-image'

const Card = ({ direction, fields }) => {
  // true=vertical
  // false=horizontal
  return (
    <li className={`flex flex-col ${direction}`} >
      <div className={`component-card bg-white flex flex-col ${direction === true && ('sm:flex-row')} flex-1 relative`}>
        {get(fields.icon, 'fixed') && (
          <div className={`${direction === true && ('sm:mt-1')} flex items-center justify-center h-12 w-12 rounded-md bg-blue-500 text-white mb-3`}>
            <Img className='w-8 h-8' fixed={fields.icon.fixed} alt={fields.icon.alt} />
          </div>
        )}
        {get(fields.image, 'fluid') && (
          <div className={`${direction === true ? ('sm:w-1/3 mb-3 sm:mb-0'):('w-full mb-3')}`}>
            <Img className={`${direction === true && ('w-full sm:h-full top-0 left-0')} object-center object-cover shadow-lg rounded-lg `} fluid={fields.image.fluid} alt={fields.image.alt} />
          </div>
        )}
        <div className={`${direction === true && ('sm:ml-3 lg:ml-6')} flex flex-col mb-3 flex-1`}>
          {get(fields.title, 'text') && (
            <h2 className='uppercase text-base font-semibold mb-3 text-blue-500 mt-0'>
              {fields.title.text}
            </h2>
          )}
          {fields.intro && (
            <p className='text-2xl font-bold tracking-tight sm:text-3xl mb-3'>
              {fields.intro}
            </p>
          )}
          <div className='text-lg text-gray-700' dangerouslySetInnerHTML={{__html:get(fields, 'text.html')}} />
          {get(fields.button_link, 'url') && (
            <div className={`${direction !== true && ('pass')} mt-auto pt-2`}>
              <CheckLink className={`${fields.button_text && ('link')}`} link={fields.button_link}>{fields.button_text ? (fields.button_text):(`More about ${fields.title.text}`)}</CheckLink>
            </div>
          )}
        </div>
      </div>
    </li>
  )
}

Card.propTypes = {
  fields: PropTypes.object.isRequired,
  direction: PropTypes.bool.isRequired
}

export default Card
