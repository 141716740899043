import React from 'react'
import PropTypes from 'prop-types'
import Card from './card'

const CardDeck = ({ slice }) => {
  // true=vertical
  // false=horizontal

  var gridClass = 'sm:grid lg:grid-cols-3'
  var gridLength = parseInt(slice.primary.cards_per_row)
  if (slice.primary.deck_direction === true) {
    gridClass = 'grid-cols-1'
  } else if (gridLength < 5) {
    gridClass = 'sm:grid-cols-2 md:grid-cols-' + gridLength
  }
  return (
    <div className={`component-${slice.slice_type} sm:grid`}>
      {slice.primary.deck_title && (
        <div className='deck-title font-bold mb-3 lg:mb-6' dangerouslySetInnerHTML={{__html:slice.primary.deck_title.html}} />
      )}
      <ul className={`grid gap-6 lg:gap-8 ${gridClass}`}>
        {slice.items.map((card, index) => {
          return (
            <Card fields={card} direction={slice.primary.deck_direction} key={`card-${index}`} />
          )
        })}
      </ul>
    </div>
  )
}

CardDeck.propTypes = {
  slice: PropTypes.object.isRequired,
}

export default CardDeck
