import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import get from 'lodash/get'
import Img from 'gatsby-image'


const News = ({ blogPosts, slice, path }) => {
  var loop = blogPosts.edges.slice(0, 3)
  if (path === '/news' || path === '/bg/news' ) {
    loop = blogPosts.edges
  }
  return (
    <div className='relative'>
      <div className={`component-${slice.slice_type}`}>
        {slice.primary.title && (
          <div className='mb-3 font-black' dangerouslySetInnerHTML={{__html:get(slice.primary, 'title.html')}} />
        )}
      </div>
      <ul>
        {loop.map((blogpost, index) => {
          var image = null
          if (blogpost.node.data.body) (
            image = get(blogpost.node.data, 'body[0].items[0].image')
          )
          return (
            <li key={`blogpost-${index}`} className='rounded-xl bg-white transform translate ease duration-200 flex flex-1 mb-6 shadow-lg hover:shadow-lg hover:scale-105'>
              <Link className='flex flex-1 focus:outline-none focus:ring-2 ring-offset-2 focus:ring-brand-blue focus:ring-opacity-50' to={blogpost.node.url}>
                <div className='w-1/3 md:w-1/4'>
                  {image.fluid && <Img className='relative overflow-hidden bg-gray-500 md:object-contain h-full w-full rounded-l-xl' fluid={image.fluid} alt={image.alt || ''}/>}
                </div>
                <div className='w-2/3 md:w-3/4 p-3 lg:p-6 flex flex-col flex-1'>
                  <h2 className='text-2xl mt-0 mb-3'>{blogpost.node.data.title}</h2>
                  <div>{blogpost.node.data.blog_introduction}</div>
                  <div className='text-blue-600 mt-auto pt-3 text-base'><strong>{blogpost.node.data.author || "Unicard"}</strong> {blogpost.node.data.first_publish_date || blogpost.node._meta.first_publication_date}</div>
                </div>
              </Link>
            </li>
          )
        })}
      </ul>
    </div>
  )
}


News.propTypes = {
  blogPosts: PropTypes.object.isRequired,
  slice: PropTypes.object.isRequired,
}

export default News
