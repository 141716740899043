import React from 'react'
import PropTypes from 'prop-types'
import Accordion from './accordion'
import BannerWithCaption from './bannerWithCaption'
import CardDeck from './cardDeck'
import Contact from './contact'
import Counter from './counter'
import Headshots from './headshots'
import Image from './image'
import Jobs from './jobs'
import Logos from './logos'
import Map from './map'
import News from './news'
import Quote from './quote'
import Text from './text'
import Timeline from './timeline'
import Video from './video'

const SliceList = ({ blogPosts, jobPosts, lang, path, slice }) => {
  return (
    <>
      {slice.slice_type === 'accordion' && (
        <div className='max-w-5xl w-full mx-auto px-4 sm:px-6 pb-4 sm:pb-8'>
          <Accordion slice={slice} />
        </div>
      )}
      {slice.slice_type === 'banner_with_caption' && (
        <div className='max-w-screen-xl w-full mx-auto px-4 sm:px-6 pb-4 sm:pb-10'>
          <BannerWithCaption slice={slice} />
        </div>
      )}
      {slice.slice_type === 'card_deck' && (
        <div className='max-w-screen-xl w-full mx-auto px-4 sm:px-6 pb-4 sm:pb-8'>
          <CardDeck slice={slice} />
        </div>
      )}
      {slice.slice_type === 'contact' && (
        <div className='max-w-2xl mx-auto px-4 sm:px-6 pb-4 sm:pb-8'>
          <Contact slice={slice} lang={lang} />
        </div>
      )}
      {slice.slice_type === 'counter' && (
        <div className='max-w-screen-xl mx-auto px-4 sm:px-6 pb-4 sm:pb-8'>
          <Counter slice={slice} />
        </div>
      )}
      {slice.slice_type === 'headshots' && (
        <div className='max-w-5xl mx-auto px-4 sm:px-6 pb-4 sm:pb-8'>
          <Headshots slice={slice} />
        </div>
      )}
      {slice.slice_type === 'image' && (
        <div className='max-w-screen-xl mx-auto px-4 sm:px-6 pb-4 sm:pb-8'>
          <Image autoplay delayLength={6000} slice={slice} />
        </div>
      )}
      {slice.slice_type === 'jobs' && (
        <div className='max-w-5xl mx-auto px-4 py-8 sm:px-6 sm:py-20 flex mx-auto relative'>
          <Jobs slice={slice} jobPosts={jobPosts} />
        </div>
      )}
      {slice.slice_type === 'logos' && (
        <div className='bg-gradient-to-b from-white to-gray-100'>
          <div className='max-w-screen-xl mx-auto pb-4 relative sm:pb-8'>
            <Logos slice={slice} />
          </div>
        </div>
      )}
      {slice.slice_type === 'map' && (
        <div className='max-w-5xl mx-auto px-4 sm:px-6 pb-4 sm:pb-8'>
          <Map slice={slice} />
        </div>
      )}
      {slice.slice_type === 'news' && (
        <div className='max-w-5xl mx-auto relative px-4 sm:px-6 pb-4 sm:pb-8'>
          <News slice={slice} blogPosts={blogPosts} path={path} />
        </div>
      )}
      {slice.slice_type === 'quote' && (
        <div className='max-w-5xl mx-auto px-4 sm:px-6 pb-4 sm:pb-8'>
          <Quote slice={slice} />
        </div>
      )}
      {slice.slice_type === 'text' && (
        <div className='max-w-5xl mx-auto px-4 sm:px-6 pb-4 sm:pb-8'>
          <Text slice={slice} />
        </div>
      )}
      {slice.slice_type === 'timeline' && (
        <div className='max-w-screen-xl w-full mx-auto px-4 sm:px-6 pb-4 sm:pb-8'>
          <Timeline slice={slice} />
        </div>
      )}
      {slice.slice_type === 'video' && (
        <div className='max-w-3xl w-full mx-auto px-4 sm:px-0 pb-4 sm:pb-8'>
          <Video slice={slice} />
        </div>
      )}
    </>
  )
}

SliceList.propTypes = {
  blogPosts: PropTypes.object,
  jobPosts: PropTypes.object,
  lang: PropTypes.string,
  slice: PropTypes.object.isRequired,
}

export default SliceList
