import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import get from 'lodash/get'

const Jobs = ({ jobPosts, slice }) => {
  if (!jobPosts) return null
  return (
    <div className='relative'>
      <div className={`component-${slice.slice_type} text-center`}>
        {slice.primary.title && (
          <div className='mb-3' dangerouslySetInnerHTML={{__html:get(slice.primary, 'title.html')}} />
        )}
        {slice.primary.text && (
          <div className='mb-3 text-xl' dangerouslySetInnerHTML={{__html:get(slice.primary, 'text.html')}} />
        )}
      </div>
      <ul>
        {jobPosts.edges.map((job, index) => {
          return (
            <li key={`job-${index}`} className='rounded-xl bg-white transform translate ease duration-200 flex flex-1 mb-6 shadow-lg hover:shadow-lg hover:scale-105'>
              <Link className='flex flex-1' to={job.node.url}>
                <div className='p-5 flex flex-col flex-1'>
                  <div className='uppercase text-base font-semibold mb-3 text-blue-500'>{job.node.data.contract_type} - {job.node.data.location}</div>
                  <h2 className='text-2xl mt-0 mb-5'>{job.node.data.page_title}</h2>
                  <div>{job.node.data.job_introduction}</div>
                  <div className='mt-3 text-blue-600 text-base'>{job.node.first_publication_date}</div>
                </div>
              </Link>
            </li>
          )
        })}
      </ul>
    </div>
  )
}

Jobs.propTypes = {
  jobPosts: PropTypes.object.isRequired,
  slice: PropTypes.object.isRequired,
}

export default Jobs
