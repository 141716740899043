import React from 'react'
import PropTypes from 'prop-types'
import CheckLink from '../components/checkLink'
import Img from 'gatsby-image'
import get from 'lodash/get'


const BannerWithCaption = ({ slice }) => {
  var position = 'left'
  if (get(slice.primary, 'image_position') === true) {
    position = 'right'
  }
  return (
    <div className={`component-${slice.slice_type} relative bg-blue-600 rounded-xl overflow-hidden ${position}`}>
      <div className={`h-56 sm:h-72 md:absolute ${position}-0 md:h-full md:w-1/2`}>
        <Img className='w-full h-full object-cover' fluid={slice.primary.image.fluid} alt={slice.primary.image.alt}/>
      </div>
      <div className='relative max-w-7xl mx-auto py-8 md:py-12 lg:py-16'>
        <div className={`${position === 'left' ? 'md:ml-auto' : 'md:mr-auto'} md:w-1/2 px-8 md:px-10`}>
          {get(slice.primary, 'title.text') && (
            <h2 id={slice.primary.title.text.split(' ').join('-').toLowerCase()} className='text-base font-semibold uppercase tracking-wider text-gray-300 mt-0'>{slice.primary.title.text}</h2>
          )}
          {get(slice.primary, 'intro') && (
            <p id={slice.primary.intro.split(' ').join('-').toLowerCase()} className='mt-2 text-white text-3xl font-bold tracking-tight sm:text-4xl'>
              {slice.primary.intro}
            </p>
          )}
          {get(slice.primary, 'text.html') && (
            <div className='mt-3 text-lg text-gray-300' dangerouslySetInnerHTML={{__html:slice.primary.text.html}} />
          )}
          {slice.primary.button_link.url && (
            <div className='mt-8'>
              <div className='inline-flex rounded-md shadow'>
                <CheckLink className='button text-xl bg-transparent text-white border-white hover:border-white hover:text-blue-600 hover:bg-white' link={slice.primary.button_link}>
                  {slice.primary.button_text}
                </CheckLink>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

BannerWithCaption.propTypes = {
  slice: PropTypes.object.isRequired,
}

export default BannerWithCaption
