import React from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get';

const Timeline = ({ slice }) => {
  return (
    <div className={`component-${slice.slice_type}`}>
      <div dangerouslySetInnerHTML={{__html:get(slice.primary, 'title.html')}} />
      <ul className='px-4'>
        {slice.items.map((time, index) => {
          var position = 'right'
          if(index % 2 !== 0) {
            position = 'left'
          }
          return (
            <li className={`p-4 relative md:w-1/2 border-l-4 border-blue-500 text-left ml-auto mr-1 ${!get(time, 'date') && 'border-dotted'} ${position === 'right' && ('md:border-l-0 md:border-r-4 border-blue-500 md:text-right md:col-start-1 md:ml-0')}`} key={`time-${index}`}>
              {get(time, 'date') && (
                <>
                  <svg className={`text-blue-500 absolute h-5 w-5 -left-3 mt-1 ${position === 'right' && ('md:-right-3 md:left-auto')}`} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='currentColor'><path d='M0 0h24v24H0z' fill='none'/><path d='M0 0h24v24H0z' fill='#fff'/><path d='M2 12C2 6.48 6.48 2 12 2s10 4.48 10 10-4.48 10-10 10S2 17.52 2 12zm10 6c3.31 0 6-2.69 6-6s-2.69-6-6-6-6 2.69-6 6 2.69 6 6 6z'/></svg>
                  <div className='text-xl font-bold text-blue-500'>{get(time, 'date')}</div>
                </>
              )}
              <div className={`my-3 text-base ${!get(time, 'date') && ('text-gray-500')}`} dangerouslySetInnerHTML={{__html:get(time, 'text.html')}} />
            </li>
          )
        })}
      </ul>
    </div>
  )
}

Timeline.propTypes = {
  slice: PropTypes.object.isRequired
}

export default Timeline
