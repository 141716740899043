import React from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get';
import CheckLink from './checkLink'


const Text = ({ slice }) => {
  return (
    <div className={`component-${slice.slice_type} leading-7 text-lg`}>
      <div dangerouslySetInnerHTML={{__html:get(slice.primary, 'text.html')}} />
      {(slice.primary.button_link.url && slice.primary.button_text) && <div className='mt-5 flex justify-center mx-auto'><CheckLink className='button bg-blue-500 text-white border-blue-700 hover:text-current hover:bg-transparent hover:border-current' link={slice.primary.button_link}>{slice.primary.button_text}</CheckLink></div>}
    </div>
  )
}

Text.propTypes = {
  slice: PropTypes.object.isRequired
}

export default Text
