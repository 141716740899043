import React from "react"
import PropTypes from "prop-types"
import get from "lodash/get"
import CheckLink from "../components/checkLink"
import Img from "gatsby-image"

const Logo = ({ company }) => {
  return (
    <>
      <div className="w-24 lg:w-32 m-3 lg:m-1 flex-1 transform translate ease duration-200 hover:scale-105">
        <Img fluid={company.logo.fluid} alt={company.logo.alt} />
      </div>
    </>
  )
}

const Logos = ({ slice }) => {
  return (
    <div className="pt-4 partner-logos">
      {get(slice.primary, "title.html") && (
        <div
          className="mb-10 font-bold text-center"
          dangerouslySetInnerHTML={{ __html: get(slice.primary, "title.html") }}
        />
      )}
      <ul
        className={`component-${slice.slice_type} flex justify-center flex-wrap`}
      >
        {slice.items.map((company, index) => {
          return (
            <li
              className="flex items-center mb-4 lg:px-5 text-center"
              key={`company-${index}`}
            >
              {company.link.url && company.link.url !== "" ? (
                <CheckLink link={company.link}>
                  <Logo company={company} />
                </CheckLink>
              ) : (
                <Logo company={company} />
              )}
            </li>
          )
        })}
      </ul>
    </div>
  )
}

Logos.propTypes = {
  slice: PropTypes.object.isRequired,
}

export default Logos
